.transform {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.proceedBtn {
  width: 17.25rem !important;
  height: 2.25rem;
  background: transparent linear-gradient(263deg, #84fab0 0%, #8fd3f4 100%) 0% 0% no-repeat padding-box;
  border-radius: 0.313rem;
  border: none;
  font: 0.813rem "General Sans", sans-serif;
  font-weight: 500;
}