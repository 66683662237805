$color_1: #141c26;
$color_2: #7784a1;
$background-color_2: #84fab0;
$border-color_1: #a0aecb;
$border-color_2: #d0d0e0;
$font-family_1: "General Sans", sans-serif;

.img {
  height: 0.875rem !important;
  width: 0.875rem !important;
}

.numCreds {
  border-radius: 0.75rem;
  background-color: $background-color_2;
  padding: 0.188rem;
  height: 1.5rem;
  width: 5.625rem;
}

.linktxt {
  font-weight: 500;
  margin-top: 0.438rem;
  font: 0.813rem $font-family_1;
}

.btnCopy {
  border: 0.063rem solid $border-color_2 !important;
  height: 1.5rem;
  padding: 0.188rem 0.375rem !important;
  background-color: #fff;
  border-radius: 5px;
}

.iconTxt {
  font: 0.813rem $font-family_1;
  font-weight: 500;
}

.tab {
  font: 0.75rem $font-family_1;
  font-weight: 500;
}

.genbtn {
  width: 17.25rem;
  height: 2.25rem;
  background: transparent linear-gradient(263deg, #84fab0 0%, #8fd3f4 100%) 0%
    0% no-repeat padding-box !important;
  border-radius: 0.313rem;
  border: none;
  margin-top: 1.5rem;
  font: 0.813rem $font-family_1;
  font-weight: 500;
}

.lblColor {
  font: 0.813rem $font-family_1;
  color: $color_2 !important;
  font-weight: 500;
}

.head3 {
  font: 1.125rem $font-family_1;
  font-weight: 600;
}

.brief {
  font: 0.875rem $font-family_1;
  font-weight: 500;
}

.tag {
  font: 0.813rem $font-family_1;
  font-weight: 600;
}

.description {
  font: 0.813rem $font-family_1;
  font-weight: 500;
  padding: 10px;
}

tr {
  height: 2.5rem;
}

.textHeight {
  height: 9.25rem;
  resize: none;
  overflow: hidden;
  font: 0.813rem $font-family_1;
  font-weight: 500;
  padding: 8px 8px 8px 8px;
}

.inActiveClr {
  color: $color_2;
}

.truncate-line-clamp {
  display: -webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  // width: 370px !important;
  overflow: hidden !important;
}
li {
  list-style: none;
}
ul {
  margin: 0 !important;
  padding: 0% !important;
}

.textStyle {
  text-decoration: none;
}

.loadertrans {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100vh;
}

.loadmessage {
  font-size: 24px;
  font-weight: 500;
  margin-top: 18rem;
  width: auto;
  text-align: center;
}

.clickherebtn{
  color: red !important ;
  text-decoration: underline !important;
  padding-left:10px;
}

@media (max-width:425px) {
  .loadmessage {
    font-size: 24px;
    font-weight: 500;
    margin-top: 15rem;
    width: 20rem;
    text-align: left;
    padding-left: 1rem;
  }
}

.cursorPointer {
  cursor: pointer;
}

.copyImage {
  width: 0.875rem;
  height: 0.875rem;
  margin-top: 0.15rem;
}

.createImg {
  width: 0.875rem;
  height: 0.875rem;
}
.downloadImg {
  width: 0.875rem;
  height: 0.875rem;
  margin-bottom: 0.2rem;
}

.arrow {
  height: 1.2rem;
  width: 1.2rem;
  padding-right: 2px;
  margin-right: 2px;
}

.heading {
  border: none;
  background: none;
  padding: 0;
}

textarea {
  //line-height: 180% !important;
  border: 1px solid #a0aecb !important;
  border-radius: 5px;
}

td:nth-child(1) {
  width: 40%;
}

td:nth-child(2) {
  width: 60%;
}

@media (max-width: 340px) {
  .options span {
    padding-right: 2rem;
  }
}

@media (min-width: 768px) {
  .borderEnd {
    border-right: 0.063rem solid #dee9ff;
  }
}

@media (min-width: 720px) {
  textarea {
    line-height: 180% !important;
  }
}

@media(min-width:768px){
  .scrollable-container {
    display: flex;
    gap:30px;
    height:100vh;
    overflow:hidden;
  }
}

@media(min-width:1024px){
  .scrollable-container{
    gap:80px;
  }
}

.scrollable-div {
  flex: 1;
  overflow-y: auto;
  scrollbar-width: none; 
  -ms-overflow-style: none; 
}

.scrollable-div::-webkit-scrollbar {
  display: none;
}

// @media (min-width: 1199px){
//   .left {
//     position: absolute;
//     overflow-y: scroll; 
//   }
  
//   .right {
//     position: absolute;
//     right: 100px;
//     overflow-x: hidden;
//     overflow-y: scroll;
//     padding: 50px;
//   }
// }

// .left {
//   height:100%;
// }

// .right {
//   height:100%;
// }

.flex-container{
  flex-direction: row;
  align-items: center;
}

@media (max-width: 768px){
  .flex-container {
    flex-direction: column;
    align-items: center;
  }
}
.retry{
  color: #7784a1;
  font: 0.813rem $font-family_1;
}

@media (min-width: 768px) {
  .tabscreen {
    padding-left: 30px;
    padding-right:30px;
  }

  .flex-container{
    align-items: center;
  }
}
@media (min-width: 1199px) {
  .tabscreen {
    align-items: center;
  }

  .flex-container{
    align-items: center;
  }
}
