$color_1: #141c26;
$font-family_1: "General Sans", sans-serif;
$background-color_1: #8fd3f4;
$background-color_2: #84fab0;

.link-text {
  font-weight: 500 !important;
  color: $color_1 !important;
  margin-top: 0.688rem;
  font: 0.813rem "General Sans", sans-serif;
  text-decoration: none;
}

.link-txt {
  margin-top: 0.438rem;
}

.btn {
  border: 0.063rem solid #d0d0e0;
  font: 0.813rem "General Sans", sans-serif;
  font-weight: 500;
}

.credits {
  background-color: #8fd3f4;
  color: $color_1;
  font-weight: 500;
  font: 0.813rem "General Sans", sans-serif;
}

.circle {
  margin-top: 0.438rem;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.625rem;
  background: #8fd3f4 0% 0% no-repeat padding-box;
  color: $color_1;
  text-align: center;
  font: 0.813rem "General Sans", sans-serif;
}

.num {
  margin-top: -0.375rem;
  margin-left: -0.063rem;
}

.numCredits {
  border-radius: 0.75rem;
  background-color: $background-color_2;
  padding: 0.188rem;
  height: 1.5rem;
  width: 5.625rem;
}

.logOut {
  width: 46px;
  height: 46px;
  background-color: #fff;
}

.imgRes {
  width: 46px;
  height: 46px;
}

.dropdown-toggle::after {
  display: none !important;
}

a {
  text-decoration: none !important;
  color: #141c26 !important;
  margin-top: 1rem;
  margin-right: 1.5rem;

}
.cursorPointer{
  cursor: pointer !important;
}

.logPad{
  padding: 4px 16px !important;
}

.dropItem{
  font:0.813rem "General Sans", sans-serif;
}

.navBack{
  background-color: #141C26 !important;
}

.loading{
  width:30px;
  color: red($color: #000000);
}

.loadingText{
  color: #84fab0;
  font: bolder;
}


.creations{
  color: #ffff !important;
  font: 0.813rem "General Sans", sans-serif;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke=' rgb(255, 255, 255)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}
@media (max-width: 570px) {
  .logOut {
    width: 50%;
  }
}
