$font-family_1: "General Sans", sans-serif;
$color_1: #141c26;

.title {
  margin-top: 3rem;
}
.textBottom {
  position: absolute;
  bottom: 5%;
  right: 10%;
}

.labelForm {
  text-decoration: none;
  color: $color_1;
}

.checkWidth {
  width: 24rem;
}

@media (max-width:425px) {
  .checkWidth {
    width: 22rem;
  }
}
@media (max-width:375px) {
  .checkWidth {
    width: 18rem;
  }
}
