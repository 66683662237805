$font-family_1: "General Sans", sans-serif;
$color_1: #141c26;
$color__2: #7784a1;

.title {
  margin-top: 3rem;
}

.content {
  min-height: 80vh !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.heading {
  margin-top: 0.75rem;
}

.labelForm {
  margin-top: 1.438rem;
  font-weight: 500;
  font: 0.813rem $font-family_1;
}

.inputBox {
  margin-top: 0.75rem;
}

.loginBtn {
  width: 17.25rem !important;
  height: 2.25rem;
  background: transparent linear-gradient(263deg, #84fab0 0%, #8fd3f4 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0.313rem;
  border: none;
  margin-top: 1rem;
  font: 0.813rem "General Sans", sans-serif;
  font-weight: 500;
}

.link {
  color: $color_1;
}

.link:hover {
  color: $color_1;
}

.hideImage,
.showImage {
  position: absolute;
  right: 20px;
  bottom: 137px;
}

.form-control::-webkit-input-placeholder {
  color: $color__2 !important;
  border-color: $color__2 !important;
}

.loaderWidth {
  height: 100px !important;
  width: 100px !important;
}

.loaderTrans {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 100vh;
}

.textDecor {
  text-decoration: underline !important;
}

.content-section {
  height: 100vh !important;
  display: flex;
  align-items: center;
  padding-left: 25%;
}

.top-left {
  position: absolute;
  top: 50px;
  left: 100px;
}

.cursorPointer {
  cursor: pointer;
}

.dWidth {
}
@media (max-width: 767px) {
  .content-section {
    padding-left: 0%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
