.ShowImage,
.HideImage {
    margin-left: 250px !important;
    margin-top: -27px;
    position: absolute;
    z-index: 2;
}

.padding {
    padding: 5rem 10rem;
    border: 1px solid #D0D0E0;
}

@media (max-width:767px) {
    .padding {
        padding: 0rem !important;
        border: none;
    }
}