$color_1: #141c26;
$color_2: #7784a1;
$font-family_1: "General Sans", sans-serif;
$background-color_1: #eef9ff;
$background-color_2: #fff;
$background-color_3: #8fd3f4;
$border-color_1: #a0aecb;
$border-color_2: #8fd3f4;
$border-color_3: #ccc;
$border-color_4: #fff;
$border-color_5: #d0d0e0;

.heading {
  font-weight: 800 !important;
  font: 1.5rem $font-family_1;
  margin-bottom:1rem ;
}

.display {
  display: flex;
}

input {
  &::-webkit-input-placeholder {
    font-weight: 500;
    color: $color_1 !important;
    font: 0.813rem $font-family_1;
  }
}

.formInput {
  margin-top: 0.75rem;
}

.marginTop {
  margin-top: 6rem;
}

.borderRight {
  border-right: 0.063rem solid $border-color_1;
}

.number {
  color: $color_2;
}

.border {
  border-radius: 0.313rem;
  border-color: $border-color_1;
}

.subTitle {
  font: 1.125rem $font-family_1;
  font-weight: 600;
}

.activeBtn {
  background-color: $background-color_1 !important;
  border: 0.063rem solid $border-color_2 !important;
}

.photoBtn {
  border: 0.063rem solid $border-color_1;
  border-radius: 1.125rem !important;
  height: 2.25rem !important;
  font: 0.813rem $font-family_1 !important;
  font-weight: 500 !important;
  background-color: #fff;
}

.imgWidth {
  width: 10.875rem !important;
  height: 10.875rem !important;
}

.downld {
  border: 0.063rem solid $border-color_5;
  background-color: $background-color_2;
  border-radius: 0.313rem;
}

.generateBtn {
  width: 10.875rem !important;
  height: 2.25rem;
  margin-top: 1.5rem;
  background: transparent linear-gradient(263deg, #84fab0 0%, #8fd3f4 100%) 0%
    0% no-repeat padding-box;
  border-radius: 0.313rem;
  border: none;
  margin-top: 1rem;
  font: 0.813rem "General Sans", sans-serif;
  font-weight: 500;
}

.label {
  margin-top: 0rem;
  color: $color_2;
}

input {
  border: 0.063rem solid #a0aecb !important;
}

.loaderStyle {
  width: 3rem !important;
  height: 3rem !important;
}

.textLoader {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 8.25rem;
  background: #E9F0F8;
}

.imageLoader {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 10.875rem;
  width: 10.875rem;
  background: #e8ebf0 0% 0% no-repeat padding-box;
}

.imgThinking {
  background: #E9F0F8 ;
  width: 10.875rem !important;
  height: 10.875rem !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loaderTransform {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  height: 70vh;
}
.bigThink {
  font: 0.813rem $font-family_1 !important;
  color: $color_2;
  margin-left: 1.4rem !important;
}
.think {
  font: 0.813rem $font-family_1 !important;
  color: $color_2;
  margin-left: -0.5rem !important;
}
.loaderWdth {
  width: 42px;
  height: 24px;
  background: #E9F0F8;
}

.form-control {
  font: 1rem $font-family_1 !important;
  color: $color_1;
  height: 2.25rem;
}

.genDisable {
  background: #e8ebf0 0% 0% no-repeat padding-box;
  color: #7784a1;
  width: 10.875rem !important;
  height: 2.25rem;
  margin-top: 1.5rem;
  border-radius: 0.313rem;
  border: none;
  margin-top: 1rem;
  font: 0.813rem "General Sans", sans-serif;
  font-weight: 500;
}

.me-5 {
  margin-right: 1.85rem !important;
}

.searchBtn {
  cursor: pointer;
  border-radius: 0.313rem;
  border: 1px solid #d0d0e0;
  padding: 8px 5px 8px 4px;
  margin-top: 1rem;
  font: 0.813rem "General Sans", sans-serif;
  font-weight: 500;
  background-color: white;
}

.retryFont {
  font: 12px "General Sans", sans-serif;
}

.emptyBackground {
  background-color: #e9f0f8 !important;
  border-radius: 5px !important;
  width: 100%; 
  height: 116px;
  margin-right: 20px;
}

@media (max-width: 768px) {
  .emptyBackground {
      height: 80px; 
  }
}

@media (max-width: 1199px) {
  .display {
    display: flex;
    flex-direction: column;
  }
}

@media (min-width: 1199px) {
  .borderend {
    border-right: 0.063rem solid #dee9ff;
    height: 93vh;
  }

  .width {
    width: 23.625rem;
  }

  .sideWidth {
    width: 36.375rem;
    margin-left: 40px;
  }
}

@media (max-width:375px) {
  .retryFont {
    font: 10px "General Sans", sans-serif;
  }
  .label{
    font: 11px "General Sans", sans-serif;
  }
}