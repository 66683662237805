$font-family_1: "General Sans", sans-serif;
$color_1: #141c26;

.title {
  margin-top: 3rem;
}

.inputBox {
  margin-top: 0.75rem;
}

.link {
  color: $color_1;
}

.link:hover {
  color: $color_1;
}

.showImg,.hideImg {
  position: absolute;
  right: 20px;
  bottom: 120px;
}
.regBtn{
  width: 17.25rem;
  height: 2.25rem;
  background: transparent linear-gradient(263deg, #84fab0 0%, #8fd3f4 100%) 0% 0% no-repeat padding-box;
  border-radius: 0.313rem;
  border: none;
  margin-top: 1rem;
  font: 0.813rem "General Sans", sans-serif;
  font-weight: 500;
}
